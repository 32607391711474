// only lower case

// downloadabled
const downloadabledExt = [
    // excel
    'xls',
    'xlsx',
    'csv',
    'psv',
    'ods',
    // word
    'doc',
    'docx',
    'dot',
    'rtf',
    'odt',
    // powerpoint
    'ppt',
    'pptx',
    'potx',
    'pot',
    'ppsx',
    'pps',
    'odp',
    // general
    'zip',
]

//pdf
const pdfExt = [
    'pdf',
]

// text
const textExt = [
    // text
    'txt',
    'xml',
    'xps',
    // code
    'json',
    'html',
    'htm',
    'css',
    'scss',
    'sass',
    'less',
    'js',
    'ts',
    'vue',
    'php',
]

// audio
const audioExt = [
    'mp3',
    'waw',
    'ogg',
    'wma',
    'mpeg'
]
// vidéo
const videoExt = [
    'mp4',
    'avi',
    'mpeg',
    'wav',
    'mov',
    'flv',
    'mkv',
    'dat',
    'webm'
]
// picture
const pictureExt = [
    'jpeg',
    'jpg',
    'png',
    'gif',
    'img',
    'svg',
    'bmp'
]

export { downloadabledExt, textExt, audioExt, videoExt, pictureExt, pdfExt} 