<template>
	<span>

        <span class="d-flex align-center justify-start mb-3">
            <v-btn class="mr-2" icon @click="previous">
                <v-icon>mdi-arrow-left-bold-circle-outline</v-icon>
            </v-btn>
            <p class="ma-0">
                <span v-for="folder in currentPath">
                    <i class="cursor-pointer" v-text="folder.name" @click="currentlocation = folder.path"></i>
                    <b> / </b>
                </span>
            </p>
        </span>

        <v-divider></v-divider>

        <span v-for="file in files" v-if="file.path == currentlocation && (Array('Corrector','Animator', 'Admin', 'SuperAdmin').includes(profile.role) || new Date(file.date).getTime() < new Date().getTime())">

            <!-- folder -->
            <span class="my-3 pl-10 d-flex justify-start align-center cursor-pointer" v-if="file.type == 'folder'" @click="goToLocation(file); deleteNotif(file.id)">
                <v-badge bordered overlap color="notif" :content="checkNotifFolder(file)" v-if="checkNotifFolder(file) > 0">
                    <v-icon>mdi-folder-outline</v-icon>
                </v-badge>
                <v-icon v-else>mdi-folder-outline</v-icon>

                <strong class="pl-2 ma-0" v-text="file.name"></strong>
                <i class="ma-0 ml-5 warning--text" v-if="new Date(file.date).getTime() > new Date().getTime()" v-text="'(' + new Date(file.date).toLocaleDateString('fr-FR', { timeZone: 'Europe/Paris' }) + ' ' + new Date(file.date).toLocaleTimeString('fr-FR', { timeZone: 'Europe/Paris' }) + ')'"></i>
                <i class="ma-0 ml-5" v-else v-text="'(' + new Date(file.date).toLocaleDateString('fr-FR', { timeZone: 'Europe/Paris' }) + ' ' + new Date(file.date).toLocaleTimeString('fr-FR', { timeZone: 'Europe/Paris' }) + ')'"></i>
                <v-divider class="mx-3"></v-divider>
                <v-btn color="secondary" icon v-if="new Array('Animator', 'Admin', 'SuperAdmin').includes(profile.role)" @click.prevent.stop="openRenameModal(file)">
                    <v-icon>mdi-pencil-outline</v-icon>
                </v-btn>
                <v-btn color="error" icon v-if="new Array('Animator', 'Admin', 'SuperAdmin').includes(profile.role)" @click.prevent.stop="deleteFolder(file)">
                    <v-icon>mdi-delete-outline</v-icon>
                </v-btn>
            </span>

            <!-- file -->
            <span class="my-3 d-flex justify-start align-center cursor-pointer" v-else @click="currentFile = file; deleteNotif(file.id)">
                <v-btn fab x-small elevation="0" class="mr-2" @click.prevent.stop="downloadFile(file)">
                    <v-icon color="info">mdi-tray-arrow-down</v-icon>
                </v-btn>
                <v-badge bordered overlap color="notif" content="1" v-if="checkNotif(file.id)">
                    <v-icon>{{getIcon(file.name)}}</v-icon>
                </v-badge>
                <v-icon v-else>{{getIcon(file.name)}}</v-icon>

                <p class="pl-3 ma-0" v-text="file.name"></p>
                <i class="ma-0 ml-5 warning--text" v-if="new Date(file.date).getTime() > new Date().getTime()" v-text="'(' + new Date(file.date).toLocaleDateString('fr-FR', { timeZone: 'Europe/Paris' }) + ' ' + new Date(file.date).toLocaleTimeString('fr-FR', { timeZone: 'Europe/Paris' }) + ')'"></i>
                <i class="ma-0 ml-5" v-else v-text="'(' + new Date(file.date).toLocaleDateString('fr-FR', { timeZone: 'Europe/Paris' }) + ' ' + new Date(file.date).toLocaleTimeString('fr-FR', { timeZone: 'Europe/Paris' }) + ')'"></i>
                <v-divider class="mx-3"></v-divider>
                <v-btn color="secondary" icon v-if="new Array('Animator', 'Admin', 'SuperAdmin').includes(profile.role)" @click.prevent.stop="openRenameModal(file)">
                    <v-icon>mdi-pencil-outline</v-icon>
                </v-btn>
                <v-btn color="error" icon v-if="new Array('Animator', 'Admin', 'SuperAdmin').includes(profile.role)" @click.prevent.stop="deleteFile(file)">
                    <v-icon>mdi-delete-outline</v-icon>
                </v-btn>
            </span>

        </span>

		<v-col cols="12" class="mt-5 pa-3 d-flex justify-end" v-if="new Array('Animator', 'Admin', 'SuperAdmin').includes(profile.role)">
			<v-btn color="primary" fab @click="openCreationModal">
				<v-icon>mdi-plus</v-icon>
			</v-btn>
		</v-col>

        <v-dialog v-model="createModalDisplayed" persistent max-width="500px">
            <v-card>
                <v-card-title class="pa-0 d-flex align-center justify-end">
                    <v-btn color="secondary" icon @click="closeCreationModal">X</v-btn>
                </v-card-title>
                <v-card-text class="d-flex flex-column pa-3 pt-0">
                    <v-form class="multi-col-validation mt-6 d-flex flex-column">
                        <v-select :items="types" item-value="value" item-text="name" label="Type" v-model="creationModalData.type"></v-select>
                        <v-text-field class="mb-2" label="Nom" v-model="creationModalData.name" />
                        <v-text-field class="mb-2" type="date" label="Date de publication" v-model="creationModalData.date" />
                        <v-text-field class="mb-2" type="time" label="Heure de publication" v-model="creationModalData.time" />
                        <v-file-input v-if="creationModalData.type == 'file'" prepend-icon="mdi-file-outline" chips truncate-length="30" label="Fichier" v-model="creationModalData.file"></v-file-input>
                        <v-btn class="mt-5" color="primary" v-text="'Ajouter le ' + (creationModalData.type == 'file' ? 'fichier' : 'dossier') " @click="saveCreationModal"></v-btn>
                    </v-form>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog v-model="renameModalDisplayed" persistent max-width="500px">
            <v-card>
                <v-card-title class="pa-0 d-flex align-center justify-end">
                    <v-btn color="secondary" icon @click="closeRenameModal">X</v-btn>
                </v-card-title>
                <v-card-text class="d-flex flex-column pa-3 pt-0">
                    <v-form class="multi-col-validation mt-6 d-flex flex-column" v-if="renameData.file">
                        <v-text-field class="mb-2" label="Nom" v-model="renameData.name" />
                        <v-text-field class="mb-2" type="date" label="Date de publication" v-model="renameData.date" />
                        <v-text-field class="mb-2" type="time" label="Heure de publication" v-model="renameData.time" />
                        <v-btn class="mt-5" color="primary" v-text="'Modifier le ' + (renameData.file.type == 'file' ? 'fichier' : 'dossier') " @click="rename()"></v-btn>
                    </v-form>
                </v-card-text>
            </v-card>
        </v-dialog>

        <FileViewer v-if="currentFile" :file="currentFile" @close="currentFile = null"/>
	</span>
</template>

<script>
import Swal from 'sweetalert2/dist/sweetalert2.js'
import { getStorage, ref, getDownloadURL, uploadBytes, deleteObject } from "firebase/storage"

// classes
import File from "@/classes/File"

// components
import FileViewer from "@/components/FileViewer.vue"

// functions
import logger from "@/assets/functions/logger"
import notifier from "@/assets/functions/notifier"
import downloader from "@/assets/functions/downloader"

// config
import { downloadabledExt, textExt, audioExt, videoExt, pictureExt , pdfExt} from "@/assets/config/ExtWhiteList"

export default {
    props: ["user", "profile", "notifications", "config", "currentRoom"],
    components: {
        FileViewer
    },
    setup() {
        return {
            storage: getStorage()
        }
    },
    data() {
        return {
            currentlocation:"home",
            createModalDisplayed: false,
            creationModalData: {
                type: null,
                name: null,
                date: null,
                time: null,
                file: null,
            },
            renameModalDisplayed: false,
            renameData: {
                name: null,
                date:null,
                time:null,
                file: null,
            },
            currentFile: null,
            types: [
                {
                    name: "Dossier",
                    value: "folder",
                },
                {
                    name: "Fichier",
                    value: "file",
                },
            ],
            files: [],
            unsub: [],
        }
    },
    async created(){
        this.unsub.push(
            File.listenByRoom(this.profile.currentRoom, async (files) => {
                let tmp_files = []
                files.forEach(async file => {
                    if(file.type == "file"){
                        file.ref = ref(this.storage, file.ref)
                        file.url = await getDownloadURL(file.ref)
                    }
                    tmp_files.push(file)
                    tmp_files.sort((a, b) => {
                        if(a.type == "folder" && b.type == "file"){
                            return -1
                        }
                        if(a.type == "file" && b.type == "folder"){
                            return 1
                        }
                        if(a.name < b.name){
                            return -1
                        }
                        if(a.name > b.name){
                            return 1
                        }
                        return 0
                    })
                })
                this.files = tmp_files
            })
        )
    },
    computed: {
        currentPath() {
            let folders = this.currentlocation.split("/")
            if(folders[folders.length-1] == "home") {
            }
            let current = ""
            let tmpPath = []
            for(let folder of folders){
                if(current != "") {
                    current += "/"
                }
                current += folder 
                tmpPath.push({
                    name: folder,
                    path: current,
                })
            }
            return tmpPath
        },
    },
    methods: {
        checkNotifFolder(folder){
            let nbNotif = 0
            if (this.notifications && this.notifications.file) {
                this.files.forEach(file => {
                    if (file.path === `home/${folder.name}`) {
                        if (file.type === 'folder') nbNotif += checkNotifFolder(file)
                        for (let notif of this.notifications.file) {
                            if (notif.content === null) notif.delete()
                            if (notif.content === file.id) nbNotif++
                        }
                    }
                })
            }
            return nbNotif
        },
        checkNotif(fileId){
            if (this.notifications && this.notifications.file) {
                for (let notif of this.notifications.file) {
                    if (notif.content === null) notif.delete()
                    if (fileId === notif.content) return true
                }
                return false
            }
            return false
        },
        deleteNotif(fileId){
            if (this.notifications && this.notifications.file) {
                for (let notif of this.notifications.file) {
                    if (fileId === notif.content) {
                        notif.delete()
                        return
                    }
                }
            }
        },


        previous(){
            if(this.currentlocation == "home"){
                return
            }else{
                let folders = this.currentlocation.split("/")
                folders.pop()
                let location = ""
                for(let folder of folders){
                    if(location != "") {
                        location += "/"
                    }
                    location += folder
                }
                this.currentlocation = location
            }
        },
        goToLocation(file){
            this.currentlocation += "/" + file.name
        },


        downloadFile(file) {
            downloader.downloadfromStorage(file.ref, file.name)
        },
        getIcon(name) {
            const splitedName = name.toLowerCase().split('.')
            const ext = splitedName[splitedName.length-1]
            
            if (downloadabledExt.includes(ext)) {
                return 'mdi-file-outline'
            } else if (textExt.includes(ext)) {
                return 'mdi-file-document-outline'
            } else if (audioExt.includes(ext)) {
                return 'mdi-volume-high'
            } else if (videoExt.includes(ext)) {
                return 'mdi-movie-open-outline'
            } else if (pictureExt.includes(ext)) {
                return 'mdi-image-outline'
            } else if (pdfExt.includes(ext)) {
                return 'mdi-file-pdf-box'
            }else{
                return 'mdi-close-octagon'
            }
        },


        openCreationModal(){
            this.creationModalData.type = null
            this.creationModalData.name = null
            this.creationModalData.date = null
            this.creationModalData.time = null
            this.creationModalData.file = null
            this.createModalDisplayed = true
        },
        closeCreationModal(){
            this.creationModalData.type = null
            this.creationModalData.name = null
            this.creationModalData.date = null
            this.creationModalData.time = null
            this.creationModalData.file = null
            this.createModalDisplayed = false
        },
        async saveCreationModal(){
            if(this.creationModalData.type && this.creationModalData.date && this.creationModalData.time && this.creationModalData.name && (this.creationModalData.type == "file" && this.creationModalData.file || this.creationModalData.type == "folder")){
                
                let tmp_data = {}

                tmp_data.name = this.creationModalData.name
                tmp_data.path = this.currentlocation
                tmp_data.type = this.creationModalData.type
                tmp_data.date = new Date(this.creationModalData.date + " " + this.creationModalData.time).getTime()
                tmp_data.room = this.profile.currentRoom

                if(this.creationModalData.type == "file"){
                    let date = new Date()
                    let uploadName = date.getFullYear() + "-" + date.getMonth() + "-" + date.getDay() + "_" + date.getHours() + "h" + date.getMinutes() + "m" + date.getSeconds() + "s_" + date.getTime()
                    let fileRef = ref(this.storage, "files/" + uploadName + "." + this.creationModalData.file.name.split(".").pop())
                    await uploadBytes(fileRef, this.creationModalData.file)
                    tmp_data.ref = "files/" + uploadName + "." + this.creationModalData.file.name.split(".").pop()
                    tmp_data.name += "." + this.creationModalData.file.name.split(".").pop()
                }else if(this.creationModalData.type == "folder"){
                    tmp_data.ref = null
                }else{
                    Swal.fire({
                        title: "Erreur",
                        text: "Type inconnu",
                        icon: "error",
                        confirmButtonText: "Ok",
                    })
                }

                let newFile = new File(null, tmp_data.room, tmp_data.date, tmp_data.type, tmp_data.path, tmp_data.name, tmp_data.ref)
                newFile.save()
                .then(() => {
                    this.closeCreationModal()

                    logger.log(this.profile.id, "FILE ADD", this.profile.email + " a ajouté un fichier/dossier")
                    notifier.send(this.profile.currentRoom,"file", newFile.date, newFile.id)

                    Swal.fire({
                        title: "Fichier/Dossier envoyé",
                        text: "Le fichier/dossier a bien été envoyé",
                        icon: "success",
                        confirmButtonText: "Ok",
                    })
                })

            }else{
                Swal.fire({
                    title: "Erreur",
                    text: "Fichier/Dossier incomplet",
                    icon: "error",
                    confirmButtonText: "Ok",
                })
            }
        },
        deleteFile(file){
            Swal.fire({
                title: "Etes-vous sûr ?",
                icon: "question",
                showCancelButton: true,
                confirmButtonText: "Oui",
                cancelButtonText: "Annuler",
            }).then((result) => {
                if(result.isConfirmed){
                    File.checkIfFileIsUsed(file.ref._location.path_, file.id).then(isUsed => {
                        if (!isUsed) {
                            const fileRef = ref(this.storage, file.ref)
                            deleteObject(fileRef)
                        }
                    })
                    logger.log(this.profile.id, "FILE DELETE", this.profile.email + " a supprimé le fichier : " + file.path + "/" + file.name)
                    file.delete()
                }
            })      
        },
        async deleteFolder(folder){
            Swal.fire({
                title: "Etes-vous sûr ?",
                icon: "question",
                showCancelButton: true,
                confirmButtonText: "Oui",
                cancelButtonText: "Annuler",
            }).then(async (result) => {
                if(result.isConfirmed){
                    for(let file of this.files){
                        if(file.path.startsWith(folder.path + "/" + folder.name)){
                            if(file.type == "file"){
                                File.checkIfFileIsUsed(file.ref._location.path_, file.id).then(isUsed => {
                                    if (!isUsed) {
                                        const fileRef = ref(this.storage, file.ref)
                                        deleteObject(fileRef)
                                    }
                                })
                            }
                            file.delete()
                        }
                    }
                    logger.log(this.profile.id, "FILE DELETE", this.profile.email + " a supprimé le dossier : " + folder.path + "/" + folder.name)
                    folder.delete()
                }
            })  
        },
        openRenameModal(file){
            this.renameData.name = file.name.split(".")[0]
            this.renameData.file = file
            this.renameData.date = new Date(file.date).toISOString().slice(0,10)
            this.renameData.time = new Date(file.date).toLocaleTimeString('fr-FR', { timeZone: 'Europe/Paris' })
            this.renameModalDisplayed = true
        },
        closeRenameModal(){
            this.renameData.name = null
            this.renameData.file = null
            this.renameData.date = null
            this.renameData.time = null
            this.renameModalDisplayed = false
        },
        rename(){
            if(this.renameData.name && this.renameData.file && this.renameData.name != "" && this.renameData.date && this.renameData.time){
                let fileName = this.renameData.name
                if(this.renameData.file.type == "file"){
                    fileName += "." + this.renameData.file.name.split(".").pop()
                }
                File.updateById(this.renameData.file.id, {
                    name: fileName,
                    date: new Date(this.renameData.date + " " + this.renameData.time).getTime()
                })
                logger.log(this.profile.id, "FILE UPDATED", this.profile.email + " a modifié le fichier/dossier : " + this.renameData.path + "/" + this.renameData.name)
                notifier.send(this.profile.currentRoom,"file", this.renameData.date, this.renameData.file.id)
                this.closeRenameModal()
            }else{
                Swal.fire({
                    title: "Erreur",
                    text: "Fichier/Dossier incomplet",
                    icon: "error",
                    confirmButtonText: "Ok",
                })
            }
        },
    }
}
</script>
