import { getBlob } from "firebase/storage"

const downloader = {
    async downloadfromStorage(ref, name) {
        const blob = await getBlob(ref)
        this.downloadBlob(blob,name)
    },

    async downloadBlob(blob, name) {
        const link = document.createElement('a')
        link.setAttribute('href', URL.createObjectURL(blob))
        link.setAttribute('download', name)
        link.click()
        link.remove()
    },

    async downloadUncodedUri(uri, name) {
        const link = document.createElement('a')
        link.style.display = 'none'
        link.href = 'data:text/plain;charset=utf-8,' + encodeURIComponent(uri)
        link.download = name
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
    },
}

export default downloader