<template>
	<div v-if="new Array('User', 'Corrector', 'Animator', 'Admin', 'SuperAdmin').includes(profile.role)">
		<v-card class="my-3">
            <v-card-title>Fichiers :</v-card-title>
			<v-card-text>
				<FileList :profile="profile" :user="user" :notifications="notifications" :currentRoom="currentRoom"></FileList>
			</v-card-text>
		</v-card>
	</div>
</template>

<script>
    import logger from "@/assets/functions/logger"
    import FileList from "../components/Files/FileList.vue"

	export default {
		name: "Files",
		props: ["user", "profile", "notifications", "config", "currentRoom"],
		components: {
            FileList,
        },
	}
</script>
