<template>
    <div>
        <!-- video -->
        <v-dialog width="unset" v-model="modals.video" @click:outside="close">
            <video controls preload="auto" :src="src" style="object-fit:cover" ref="videoPlayer"></video>
        </v-dialog>

        <!-- audio -->
        <v-dialog v-model="modals.audio" @click:outside="close">
            <audio controls preload="auto" :src="src" style="width:100%" ref="audioPlayer"></audio>
        </v-dialog>

        <!-- picture -->
        <v-dialog width="unset" v-model="modals.picture" @click:outside="close">
            <img :src="src" class="max-90-90" style="object-fit:cover">
        </v-dialog>

        <!-- text -->
        <v-dialog width="unset" v-model="modals.text" @click:outside="close">
            <v-card height="100%">
                <v-card-text>
                    <pre v-text="src"></pre>
                </v-card-text>
            </v-card>
        </v-dialog>

        <!-- other file (pdf) -->
        <v-dialog width="unset" v-model="modals.pdf" @click:outside="close">
            <v-card class="container">
                <iframe :src="src" frameborder="0" allowfullscreen="" class="size-90-90"></iframe>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
// firebase
import { getStorage, ref, getBlob } from "firebase/storage"

// config
import { downloadabledExt, textExt, audioExt, videoExt, pictureExt, pdfExt } from "@/assets/config/ExtWhiteList"

// functions
import downloader from "@/assets/functions/downloader"

export default {
    props: ['file'],
    data: () => ({
        modals: {
            video: false,
            audio: false,
            picture: false,
            text: false,
            pdf: false,
            other: false
        },
        src: null
    }),
    methods: {
        close() {
            this.$emit("close")
        }
    },
    created() {
        this.src = this.file.url


        const splitedName = this.file.name.toLowerCase().split('.')
        const ext = splitedName[splitedName.length-1]

        if (videoExt.includes(ext)) {
            this.modals.video = true
            setTimeout(() => this.$refs.videoPlayer.play(), 0) // this.$refs.videoPlayer == undefined without setTimeout
        }
        else if (audioExt.includes(ext)) {
            this.modals.audio = true
            setTimeout(() => this.$refs.audioPlayer.play(), 0) // this.$refs.audioPlayer == undefined without setTimeout
        }
        else if (pictureExt.includes(ext)) {
            this.modals.picture = true
        }
        else if (textExt.includes(ext)) {
            const reader = new FileReader()
            reader.onload = () => this.src = reader.result

            const fileRef = (typeof this.file.ref === 'string') ? ref(getStorage(), this.file.ref) : this.file.ref
            getBlob(fileRef).then(blob => {
                reader.readAsText(blob)
                this.modals.text = true
            })
        }
        else if(pdfExt.includes(ext)) {
            this.modals.pdf = true
        }
        else {
            const fileRef = (typeof this.file.ref === 'string') ? ref(getStorage(), this.file.ref) : this.file.ref
            downloader.downloadfromStorage(fileRef, this.file.name)
            this.close()
        }
    }
}
</script>

<style scoped>
pre {
    white-space: pre-wrap;
}
* >>> .v-dialog {
    display: flex; /* prevent scroll bar on video file */
}
.container {
    display: flex;
    justify-content: center;
    padding: 0;
    max-width: 100%;
    max-height: 100%;
}
.size-90-90 {
    width: 90vw;
    height: 90vh;
}
.max-90-90 {
    max-width:90vw;
    max-height:90vh;
}
</style>